import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M7726 10474 c-9 -8 -16 -20 -16 -25 0 -18 -85 -102 -117 -115 -18 -8
-63 -14 -100 -14 -64 0 -118 9 -343 58 -113 25 -281 55 -405 74 -122 18 -657
18 -780 0 -341 -50 -611 -138 -784 -255 -26 -18 -58 -38 -71 -46 -113 -65
-369 -293 -460 -410 -19 -25 -47 -57 -62 -71 -38 -37 -159 -241 -197 -335 -78
-188 -115 -334 -132 -525 -9 -93 -17 -137 -28 -149 -9 -10 -52 -31 -96 -46
-84 -29 -222 -102 -320 -171 -33 -22 -78 -53 -100 -68 -82 -55 -208 -174 -300
-281 -33 -38 -63 -72 -66 -75 -3 -3 -33 -45 -66 -95 -151 -225 -240 -459 -274
-720 -15 -113 -5 -493 14 -585 25 -118 64 -245 103 -340 29 -68 132 -269 145
-280 3 -3 20 -27 39 -55 81 -122 289 -338 393 -410 120 -83 154 -104 207 -125
19 -8 55 -26 80 -40 90 -53 327 -136 495 -173 243 -55 422 -71 785 -72 508 0
818 46 1215 182 72 24 143 48 158 53 60 19 57 -4 57 459 1 431 6 530 35 619
31 93 155 193 260 209 22 3 48 10 58 15 22 11 23 64 1 72 -29 11 -1604 7
-1625 -4 -21 -12 -26 -52 -6 -68 6 -5 37 -13 67 -16 104 -13 229 -98 277 -189
31 -59 36 -121 40 -461 4 -318 2 -344 -16 -405 -40 -130 -94 -181 -233 -220
-107 -30 -317 -44 -469 -31 -119 9 -314 51 -333 71 -6 5 -20 9 -32 9 -46 0
-195 74 -276 137 -237 185 -393 449 -449 756 -5 29 -13 72 -19 97 -60 278 -64
685 -9 1030 53 339 83 434 183 584 18 28 54 83 79 121 25 39 71 96 102 127 61
63 162 148 175 148 5 0 15 6 23 14 40 40 215 111 347 141 100 23 433 32 550
15 284 -41 531 -145 735 -311 75 -61 185 -172 185 -187 0 -5 11 -20 23 -34 13
-14 39 -50 57 -79 18 -30 35 -56 39 -59 3 -3 22 -36 41 -73 38 -75 67 -96 107
-78 l23 11 -2 501 c-3 465 -4 503 -20 513 -26 16 -48 -2 -80 -64 -51 -99 -84
-115 -232 -114 -112 1 -149 6 -331 49 -381 89 -458 104 -623 120 -86 8 -138
17 -147 26 -12 12 -13 40 -4 169 15 217 64 456 105 511 5 8 18 39 28 69 11 30
27 66 36 80 9 14 30 50 46 80 82 156 213 291 372 381 17 11 49 29 70 40 58 34
218 85 324 104 137 24 423 17 557 -14 188 -44 400 -141 526 -243 179 -143 336
-389 400 -623 15 -55 30 -111 34 -125 6 -20 15 -26 45 -28 70 -6 66 -45 66
630 0 401 -4 616 -10 629 -13 23 -53 25 -74 3z"/>
<path d="M4690 8173 c-90 -16 -172 -34 -192 -42 -13 -5 -43 -15 -68 -22 -56
-16 -89 -39 -90 -60 0 -19 42 -120 93 -224 111 -223 298 -445 507 -601 256
-191 516 -299 880 -366 112 -20 156 -23 430 -23 335 0 423 9 635 65 248 66
517 190 621 287 16 15 50 43 75 62 81 63 173 144 224 200 l50 53 5 137 c7 189
7 189 -119 63 -55 -56 -127 -120 -158 -144 -32 -23 -76 -59 -98 -80 -22 -21
-43 -38 -47 -38 -4 0 -32 -16 -63 -35 -120 -77 -289 -148 -450 -188 -131 -34
-210 -42 -385 -41 -198 0 -365 35 -543 114 -228 100 -393 230 -537 425 -55 74
-161 282 -169 332 -3 19 -13 39 -21 44 -79 41 -125 55 -255 76 -43 7 -295 12
-325 6z"/>
<path d="M2860 4691 c-8 -4 -51 -11 -95 -14 -202 -15 -394 -124 -505 -287 -80
-117 -110 -202 -136 -380 -39 -267 20 -536 152 -703 89 -112 211 -185 379
-227 126 -32 357 -32 495 -1 210 48 197 47 223 17 24 -28 58 -34 75 -13 6 7
11 117 13 267 l4 255 -30 3 c-16 1 -35 -1 -41 -5 -6 -5 -31 -46 -54 -93 -59
-118 -112 -195 -184 -265 -80 -79 -132 -104 -211 -101 -155 7 -255 133 -305
386 -17 81 -23 457 -11 600 13 158 28 219 76 316 52 107 122 154 230 154 82 0
137 -25 209 -94 63 -60 119 -141 178 -257 56 -112 84 -132 122 -90 16 18 17
30 7 140 -6 65 -11 173 -11 238 0 122 -6 143 -41 143 -10 0 -31 -14 -48 -32
-26 -28 -32 -30 -58 -20 -46 18 -138 36 -258 52 -139 19 -160 20 -175 11z"/>
<path d="M8267 4694 c-4 -5 -44 -11 -87 -15 -207 -18 -365 -144 -431 -344 -18
-54 -18 -236 0 -300 52 -185 180 -292 441 -371 316 -95 397 -145 419 -258 30
-145 -78 -260 -243 -261 -82 0 -143 20 -226 74 -75 48 -198 176 -250 259 -24
37 -54 84 -67 105 -22 34 -28 38 -61 35 -37 -3 -37 -3 -36 -48 3 -203 18 -497
26 -507 18 -22 48 -14 81 22 36 39 20 39 212 1 176 -36 363 -39 475 -8 87 23
194 90 248 154 14 17 32 36 39 44 24 23 72 137 83 193 13 68 13 223 -1 280
-39 171 -163 280 -404 355 -50 15 -108 34 -130 41 -22 7 -67 21 -100 31 -83
26 -164 70 -202 111 -24 26 -33 47 -39 89 -16 135 76 223 235 224 64 0 83 -5
146 -35 108 -52 211 -162 301 -324 29 -51 53 -67 91 -59 17 3 18 17 14 233 -2
127 -8 238 -13 248 -14 26 -50 20 -82 -14 -22 -22 -35 -29 -50 -24 -70 22
-161 41 -251 54 -33 5 -76 12 -95 16 -19 4 -39 3 -43 -1z"/>
<path d="M3612 4668 c-15 -15 -16 -55 -1 -64 7 -4 37 -10 68 -13 l56 -6 2
-705 c2 -422 -1 -711 -7 -720 -6 -10 -27 -14 -67 -15 -55 0 -58 -1 -61 -27 -2
-18 4 -31 20 -42 19 -14 67 -16 360 -16 369 0 373 1 366 59 -3 24 -7 26 -59
29 -49 3 -58 6 -63 25 -8 29 -8 631 0 651 5 14 32 16 195 16 l189 0 0 -339 c0
-259 -3 -341 -12 -349 -7 -5 -34 -8 -60 -6 l-48 4 0 -39 c0 -31 4 -40 22 -44
30 -8 672 -9 692 -1 21 8 38 48 30 69 -5 11 -22 15 -65 15 -53 0 -59 2 -64 23
-3 12 -4 335 -3 717 l3 695 35 3 c59 4 79 11 90 32 8 14 8 26 0 40 -10 19 -21
20 -375 20 l-365 0 0 -39 c0 -45 10 -51 76 -51 l44 0 0 -319 c0 -282 -2 -320
-16 -325 -9 -3 -91 -6 -184 -6 -130 0 -170 3 -179 14 -8 9 -11 103 -9 322 l3
309 52 5 c62 6 76 17 71 56 l-3 29 -360 3 c-272 2 -364 -1 -373 -10z"/>
<path d="M5276 4668 c-3 -7 -6 -20 -9 -28 -11 -36 11 -50 78 -50 l65 0 0 -714
c0 -535 -3 -717 -12 -722 -6 -4 -36 -6 -66 -5 -32 1 -58 -2 -62 -9 -13 -22 2
-61 28 -71 15 -6 167 -9 374 -7 378 3 371 2 366 58 -3 24 -7 25 -70 28 l-68 3
0 354 0 355 73 0 c64 0 76 -3 95 -23 36 -38 42 -78 51 -317 9 -246 19 -294 73
-362 62 -79 228 -121 389 -98 151 22 198 77 240 284 13 63 8 73 -37 72 l-37
-1 -17 -74 c-12 -49 -26 -81 -43 -98 l-25 -25 -18 28 c-16 24 -20 57 -25 224
-9 276 -31 332 -158 405 -47 27 -48 39 -4 54 131 47 203 151 218 312 17 178
-64 335 -190 371 -22 6 -44 15 -50 19 -36 25 -313 42 -753 46 -319 3 -403 1
-406 -9z m804 -104 c61 -35 75 -79 78 -252 4 -162 -7 -223 -50 -284 -24 -35
-79 -68 -111 -68 -14 0 -29 -5 -32 -10 -4 -6 -19 -9 -33 -7 l-27 4 -3 323 -2
323 74 -5 c47 -3 85 -12 106 -24z"/>
<path d="M6887 4673 c-4 -3 -7 -19 -7 -34 0 -34 24 -49 79 -49 l41 0 0 -720
c0 -679 -1 -720 -17 -721 -10 0 -37 0 -60 1 -43 2 -43 1 -43 -34 0 -30 5 -38
26 -46 14 -6 159 -10 339 -10 180 0 325 4 339 10 21 8 26 16 26 45 l0 36 -48
-2 c-26 -1 -53 1 -60 5 -9 5 -12 178 -12 722 l0 714 43 0 c56 0 80 17 75 55
l-3 30 -356 3 c-195 1 -358 -1 -362 -5z"/>
<path d="M2095 2660 c-195 -21 -346 -89 -465 -209 -121 -122 -188 -272 -213
-481 -25 -210 2 -407 79 -567 44 -93 123 -186 201 -241 47 -32 144 -80 178
-87 17 -4 39 -11 50 -15 11 -5 76 -16 144 -25 114 -14 134 -14 230 1 58 9 140
25 181 37 41 12 105 23 141 25 63 3 69 2 102 -27 28 -25 78 -41 89 -28 1 1 5
142 8 312 l5 310 30 3 c17 2 36 8 43 14 14 12 16 60 3 73 -6 6 -143 9 -345 7
l-336 -3 0 -39 c0 -44 6 -47 90 -52 l45 -3 3 -200 c4 -236 -1 -275 -34 -315
-24 -28 -30 -30 -100 -30 -84 0 -124 19 -181 83 -93 106 -124 270 -123 647 1
393 36 558 139 657 74 70 189 86 285 39 93 -46 223 -204 315 -384 19 -37 25
-42 56 -42 36 0 60 25 50 52 -3 8 -9 116 -13 242 -6 182 -11 229 -22 236 -22
14 -27 13 -62 -20 -17 -16 -36 -31 -42 -31 -13 -2 -13 -2 -105 22 -41 10 -86
19 -101 19 -14 0 -30 4 -35 9 -14 12 -207 20 -290 11z m213 -17 c-10 -2 -26
-2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M5000 2660 c-187 -19 -326 -78 -444 -191 -163 -154 -237 -392 -223
-713 8 -164 26 -244 85 -369 48 -102 134 -200 222 -251 73 -43 187 -84 252
-91 29 -3 83 -11 120 -16 91 -14 284 11 399 51 295 104 449 373 449 782 0 322
-101 554 -300 686 -72 47 -181 92 -225 92 -12 0 -25 4 -30 9 -14 12 -216 19
-305 11z m47 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m166
-1 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-3 -93 c74 -38
111 -113 130 -261 17 -136 13 -761 -6 -888 -29 -202 -96 -281 -239 -281 -120
0 -193 65 -232 205 -24 89 -38 484 -25 756 11 247 20 308 59 384 40 76 92 104
201 105 54 0 83 -5 112 -20z"/>
<path d="M7981 2627 c-10 -23 -44 -112 -76 -197 -31 -85 -97 -262 -146 -394
-49 -131 -89 -244 -89 -252 0 -7 -7 -28 -14 -46 -8 -18 -60 -157 -116 -308
-56 -151 -105 -282 -109 -290 -5 -10 -26 -16 -59 -18 -55 -3 -67 -16 -52 -57
l10 -25 180 0 180 0 12 26 c20 44 2 58 -70 55 -78 -3 -82 5 -49 101 14 40 46
132 70 203 l45 130 208 0 208 0 33 -95 c18 -52 49 -143 69 -201 21 -58 34
-114 31 -123 -5 -12 -20 -16 -72 -16 -62 0 -65 -1 -65 -24 0 -59 -21 -56 381
-56 278 0 374 3 389 13 17 10 22 9 28 -1 7 -10 136 -12 612 -10 l604 3 12 90
c17 131 37 452 29 464 -3 6 -23 11 -43 11 -37 0 -38 -2 -77 -77 -99 -192 -201
-313 -307 -363 -53 -25 -75 -30 -141 -30 l-77 0 2 708 3 707 113 3 113 3 -3
47 -3 47 -411 3 c-402 2 -411 2 -429 -18 -15 -16 -17 -26 -9 -48 9 -25 14 -27
72 -30 l62 -3 0 -709 0 -710 -60 0 c-42 0 -62 -4 -66 -13 -5 -15 -100 -12
-119 4 -6 4 -19 32 -29 61 -38 110 -105 296 -115 319 -6 13 -33 87 -60 164
-27 77 -63 176 -79 220 -16 44 -73 199 -127 345 -123 337 -144 392 -154 410
-6 12 -32 16 -115 18 l-106 3 -19 -44z m25 -749 c38 -114 66 -209 64 -212 -9
-8 -308 -11 -317 -3 -4 5 7 52 26 105 53 155 122 346 128 356 10 15 27 -29 99
-246z"/>
<path d="M2984 2639 c-14 -15 -16 -25 -8 -47 8 -24 15 -27 69 -32 l60 -5 3
-707 2 -708 -60 0 c-48 0 -61 -4 -70 -19 -14 -27 -13 -47 6 -65 14 -14 83 -16
615 -16 l599 0 4 23 c20 100 51 520 41 538 -4 5 -23 9 -44 9 -38 0 -38 -1 -86
-92 -97 -187 -184 -289 -291 -343 -47 -23 -75 -30 -140 -33 -78 -4 -81 -3 -87
20 -9 30 -9 1361 -1 1382 5 13 25 16 114 16 89 0 109 3 114 16 10 27 7 61 -8
73 -10 8 -128 11 -414 11 -386 -1 -400 -2 -418 -21z"/>
<path d="M5945 2640 c-15 -16 -16 -25 -7 -45 10 -21 18 -24 74 -27 l63 -3 3
-714 c2 -565 0 -716 -10 -723 -7 -4 -39 -8 -70 -8 -48 0 -58 -3 -63 -21 -4
-13 0 -28 12 -41 18 -20 24 -20 453 -15 389 4 445 7 530 25 156 34 226 64 302
131 47 42 108 146 108 184 0 11 5 35 12 53 15 42 -2 164 -33 233 -38 86 -121
154 -235 190 -32 10 -59 24 -59 31 0 7 27 28 60 45 126 68 187 175 187 325 0
111 -26 182 -90 248 -55 56 -151 99 -243 109 -32 3 -59 9 -59 14 0 12 -175 20
-561 24 -345 4 -357 4 -374 -15z m767 -98 c52 -30 67 -95 68 -289 0 -146 -12
-231 -37 -270 -26 -39 -78 -65 -125 -61 l-43 3 -3 310 c-1 170 0 316 3 323 7
18 98 7 137 -16z m4 -733 c48 -15 90 -61 110 -121 28 -84 25 -322 -5 -416 -34
-103 -72 -133 -195 -149 -26 -4 -41 -1 -47 8 -10 16 -12 682 -2 692 8 8 95 -1
139 -14z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
